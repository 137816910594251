<template>
  <div>
    <time-log-navigation />

    <div class="container">
      <div class="month-selector">
        <custom-select
          v-model="selectedMonth"
          :options="makePeriod('month')"
          :datepicker="true"
          label="Month"
          @input="changedMonth"
        ></custom-select>
      </div>

      <div
        v-if="teamTimeLog.length"
        class="team-time-log"
      >
        <div class="team-time-log__item">
          <h2 class="team-time-log__tag">
            Total cdm spent this month (by project type): {{ fromMillisecondsToHours(getTotalDuration) }}h
          </h2>

          <ul class="team-time-log__by-projects" v-if="getInternalProjectsTotalDuration > 0">
            <li>
              <div class="team-time-log__by-projects-title">Internal projects: {{ fromMillisecondsToHours(getInternalProjectsTotalDuration) }}h</div>

              <ul class="team-time-log__by-projects-list">
                <li v-if="getMktIntTotalDuration > 0">SEO - {{ fromMillisecondsToHours(getMktIntTotalDuration) }}h</li>
                <li v-if="getDevIntTotalDuration > 0">DEV - {{ fromMillisecondsToHours(getDevIntTotalDuration) }}h</li>
                <li v-if="getOthIntTotalDuration > 0">OTH - {{ fromMillisecondsToHours(getOthIntTotalDuration) }}h</li>
              </ul>
            </li>
          </ul>

          <ul class="team-time-log__by-projects" v-if="getClientsProjectsTotalDuration > 0">
            <li>
              <div class="team-time-log__by-projects-title">Clients projects: {{ fromMillisecondsToHours(getClientsProjectsTotalDuration) }}h</div>

              <ul class="team-time-log__by-projects-list">
                <li v-if="getMktTotalDuration > 0">SEO - {{ fromMillisecondsToHours(getMktTotalDuration) }}h</li>
                <li v-if="getDevTotalDuration > 0">DEV - {{ fromMillisecondsToHours(getDevTotalDuration) }}h</li>
                <li v-if="getMntTotalDuration > 0">MNT - {{ fromMillisecondsToHours(getMntTotalDuration) }}h</li>
              </ul>
            </li>
          </ul>
        </div>

        <div
          v-for="itemByActivityTag in getGroupedTeamTimeLog"
          :key="itemByActivityTag.userActivityTagId"
          class="team-time-log__item"
        >
          <h2 class="team-time-log__tag">{{ itemByActivityTag.userActivityTagName }}</h2>

<!--          <div class="team-time-log__table" v-if="itemByActivityTag.userActivityTagName === 'development'">-->
          <div class="team-time-log__table">
            <div
              v-for="itemByUser in itemByActivityTag.byUser"
              :key="itemByUser.userId"
              class="team-time-log__table-item"
            >
              <div class="team-time-log__table-item__info">
                <div class="team-time-log__table-item-user">
                  {{ itemByUser.userFullName }}
                  <span class="user-tz">{{ itemByUser.userTimeZone }}</span>
                </div>
                <div class="team-time-log__table-item-months">
                  <div
                    v-for="itemByMonth in itemByUser.byMonths"
                    :key="itemByMonth.month"
                    class="team-time-log__table-item-month"
                  >
                    <div class="team-time-log__table-item-month-data">
                      <div
                        v-for="(itemByWeek, key) in itemByMonth.byWeeks"
                        :key="key"
                        class="team-time-log__table-item-month-week"
                      >
                        <div
                          class="team-time-log__table-item-month-week-head"
                          :class="{
                            'team-time-log__table-item-month--week-selected': isWeekSelected(itemByActivityTag.userActivityTagId, itemByUser.userId, key)
                          }"
                             @click="weekClicked(itemByActivityTag.userActivityTagId, itemByUser.userId, --key)"
                        >
                          W{{ ++key }} ({{ fromMillisecondsToHours(calcWeekTime(itemByWeek)) }}h)
                        </div>

                        <div class="team-time-log__table-item-month-days">
                          <div
                            v-for="item in itemByWeek"
                            :key="item.day"
                            class="team-time-log__table-item-month-day"
                            :class="{
                              'team-time-log__table-item-month--day-selected': isDaySelected(itemByActivityTag.userActivityTagId, itemByUser.userId, item.day)
                            }"
                            @click="dayClicked(itemByActivityTag.userActivityTagId, itemByUser.userId, item.day)"
                          >
                            <div class="team-time-log__table-item-month-day-number">
                              {{ getDay(item.day) }}
                            </div>
                            <div
                              class="team-time-log__table-item-month-day-duration"
                              :style="{ 'background': getColorByDuration(fromMillisecondsToHours(item.duration), item.day) }"
                            >
                              {{ fromMillisecondsToHours(item.duration) }}h
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="team-time-log__table-item-month-info"
                      :class="{
                        'team-time-log__table-item-month-info--month-selected': isMonthSelected(itemByActivityTag.userActivityTagId, itemByUser.userId, itemByMonth.month)
                      }"
                      @click="monthClicked(itemByActivityTag.userActivityTagId, itemByUser.userId, itemByMonth.month)"
                    >
                      {{ monthFormatted(itemByMonth.month) }} <div>({{ fromMillisecondsToHours(itemByMonth.monthDuration) }}h)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isUserSelected(itemByUser.userId)" class="team-time-log__table-item__tasks">
                <div
                  v-if="!tasksTimeLog"
                  class="loader"
                ></div>
                <template v-else>
                  <div v-if="tasksTimeLog.length">
                    <div
                      v-for="(project, index) in getSortTasksTimeLog"
                      :key="index"
                      class="team-time-log__table-item__tasks-item"
                    >
                      <div class="team-time-log__table-item__project-alias">{{ project.alias }} ({{ TimeConverter.millisecondsToTime(project.duration) }})</div>
                      <ul>
                        <li v-for="(item, key) in project.items" :key="key">
                          <a :href="item.task_url" target="_blank">{{ item.task_name }}</a> ({{ item.duration.for_humans }})
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span v-else>Tasks not found</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeLogNavigation from '@/components/TimeLogNavigation.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import dayjs from 'dayjs'
import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import CustomSelect from '../components/ui/CustomSelect'
import { makePeriod } from '@/mixins/period'
import { TimeConverter } from '@/services'

export default {
  name: 'TeamTimeLog',

  components: {
    TimeLogNavigation,
    CustomSelect
  },

  data() {
    return {
      ops: {
        scrollPanel: {
          easing: 'easeOutQuad'
        },
        rail: {
          background: 'rgba(233, 233, 233, 0.25)',
          gutterOfSide: 0
        },
        bar: {
          keepShow: true,
          background: '#848484'
        },
      },

      itemSelected: null
    }
  },

  async mounted() {
    this.setViewLoader(true)

    this.setSelectedMonth({
      label: dayjs().format(monthYear),
      code: dayjs().format(yearMonthDay)
    })

    await this.getData(this.selectedMonth.code)

    this.setViewLoader(false)
  },

  computed: {
    TimeConverter() {
      return TimeConverter
    },
    ...mapState({
      teamTimeLog: (state) => state.teamTimeLog.teamTimeLog,
      tasksTimeLog: (state) => state.teamTimeLog.tasksTimeLog,
      selectedMonth: (state) => state.teamTimeLog.selectedMonth,
    }),
    ...mapGetters([
      'getGroupedTeamTimeLog',
      'getSortTasksTimeLog',
      'getTotalDuration',
      'getInternalProjectsTotalDuration',
      'getClientsProjectsTotalDuration',
      'getMktIntTotalDuration',
      'getDevIntTotalDuration',
      'getOthIntTotalDuration',
      'getMktTotalDuration',
      'getDevTotalDuration',
      'getMntTotalDuration',
    ]),

    selectedMonth: {
      get() {
        return this.$store.state.teamTimeLog.selectedMonth
      },
      set(value) {
        this.setSelectedMonth(value)
      }
    },
  },

  methods: {
    ...mapMutations(['setViewLoader', 'setDateFrom', 'setDateTo', 'setTasksTimeLog', 'setSelectedMonth']),
    ...mapActions(['fetchTeamTimeLog', 'fetchTasksTimelog']),

    makePeriod,

    calcWeekTime(items) {
      return items.reduce((acc, cur) => acc + cur.duration, 0)
    },

    async changedMonth() {
      this.setViewLoader(true)

      this.itemSelected = null

      await this.getData(this.selectedMonth.code)

      this.setViewLoader(false)
    },

    async getData(month) {
      await this.fetchTeamTimeLog({
        month
      })
    },

    getDay(date) {
      return dayjs(date).date()
    },

    monthFormatted(date) {
      return dayjs(date).format(monthYear)
    },

    fromMillisecondsToHours(duration) {
      return this.round(duration / 3600000)
    },

    round(number) {
      return Math.round(number * 10) / 10
    },

    isWeekend(day) {
      return dayjs(day).day() === 0 || dayjs(day).day() === 6
    },

    getColorByDuration(duration, day) {
      if (this.isWeekend(day)) {
        return '#FFFFFF'
      }

      if (duration <= 3) {
        return '#FF3D34'
      } else if (duration > 3 && duration <= 5) {
        return '#FFCC17'
      } else if (duration > 5 && duration <= 6) {
        return '#A5FBA6'
      } else if (duration > 6 && duration <= 7) {
        return '#49E54A'
      } else if (duration > 7 && duration <= 8) {
        return '#30CB00'
      } else if (duration > 8 && duration < 12) {
        return '#0D9201'
      } else if (duration >= 12) {
        return '#616ff6'
      }
    },

    isDaySelected(activityTagId, userId, day) {
      return this.itemSelected &&
        (this.itemSelected.activityTagId === activityTagId
         && this.itemSelected.userId === userId
         && this.itemSelected.day === day
        )
    },

    isWeekSelected(activityTagId, userId, week) {
      return this.itemSelected &&
        (this.itemSelected.activityTagId === activityTagId
         && this.itemSelected.userId === userId
         && this.itemSelected.week === week
        )
    },

    isMonthSelected(activityTagId, userId, month) {
      return this.itemSelected &&
        (this.itemSelected.activityTagId === activityTagId
         && this.itemSelected.userId === userId
         && this.itemSelected.month === month
        )
    },

    isUserSelected(userId) {
      return this.itemSelected && this.itemSelected.userId === userId
    },

    async dayClicked(activityTagId, userId, day) {
      this.setTasksTimeLog(null)

      if (this.isDaySelected(activityTagId, userId, day)) {
        this.itemSelected = null
      } else {
        this.itemSelected = {
          activityTagId,
          userId,
          day,
        }

        const currentDate = new Date();
        const currentTimezoneOffset = currentDate.getTimezoneOffset();
        const currentTimezoneHours = Math.abs(Math.floor(Number(currentTimezoneOffset) / 60));
        // console.log('--------------------------------', day)
        // const clearDate = dayjs(day).format().split('T')[0]
        const dateFrom = dayjs(day).add(currentTimezoneHours, 'hours')
        const dateTo = dayjs(day)
          .add(1, 'day')
          .add(currentTimezoneHours, 'hours')

        await this.fetchTasksTimelog({
          userId,
          dateFrom: dateFrom.toISOString(),
          dateTo: dateTo.toISOString(),
          // dateFrom: dateFrom.toISOString().split('T')[0],
          // dateTo: dateTo.toISOString().split('T')[0] + 'T23:59:59'
        })
      }
    },

    async weekClicked(activityTagId, userId, week) {
      this.setTasksTimeLog(null)

      if (this.isWeekSelected(activityTagId, userId, week)) {
        this.itemSelected = null
      } else {
        this.itemSelected = {
          activityTagId,
          userId,
          week,
        }

        let selectedDates = []

        let foundedTag = this.getGroupedTeamTimeLog.find(item => item.userActivityTagId === activityTagId)

        if (!foundedTag) {
          throw Error('Activity tag not found')
        }

        let foundedUser = foundedTag.byUser.find(item => item.userId === userId)

        if (!foundedUser) {
          throw Error('User not found')
        }

        for (let month of foundedUser.byMonths) {
          let firstDay = month.byWeeks[week][0]['day']

          let lastDay = month.byWeeks[week].at(-1)['day']

          selectedDates.push(firstDay, lastDay)
        }

        const dateFrom = dayjs(selectedDates[0])
        const dateTo = dayjs(selectedDates[1]).add(1, 'day')

        await this.fetchTasksTimelog({
          userId,
          dateFrom: dateFrom.toISOString(),
          dateTo: dateTo.toISOString(),
        })
      }
    },

    async monthClicked(activityTagId, userId, month) {
      this.setTasksTimeLog(null)

      if (this.isMonthSelected(activityTagId, userId, month)) {
        this.itemSelected = null
      } else {
        this.itemSelected = {
          activityTagId,
          userId,
          month,
        }

        const dateFrom = dayjs(month)
        const dateTo = dayjs(month).add(1, 'month')

        await this.fetchTasksTimelog({
          userId,
          dateFrom: dateFrom.toISOString(),
          dateTo: dateTo.toISOString(),
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

.links {
  display: flex;

  > *:not(:last-child) {
    margin-right: 20px;
  }
}

.team-time-log {
  padding-top: 25px;
  padding-bottom: 50px;
  overflow: hidden;
}

.team-time-log__item {
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}

.team-time-log__tag {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}

.team-time-log__table-head {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 8px 20px;
  background: #F9F9F9;
  display: flex;
  justify-content: flex-end;
}

.team-time-log__table-duration {
  background-color: #F9F9F9;
  display: flex;
}

.team-time-log__table-item-row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.team-time-log__table-item-name {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.team-time-log__table-item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.team-time-log__table-item-user {
  width: 130px;
  min-width: 130px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  display: flex;
  flex-direction: column;
}

.team-time-log__table-item-months {
  width: 100%;
  display: flex;
  padding-bottom: 15px;
}

.team-time-log__table-item-month {
  display: flex;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.team-time-log__table-item-month-head {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 8px 20px;
  background: #f9f9f9;
  display: flex;
  justify-content: flex-end;
  min-height: 35px;
  align-items: center;
  white-space: nowrap;
}

.team-time-log__table-item-month-data {
  display: flex;
  margin-right: 20px;
}

.team-time-log__table-item-month-day {
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:not(.team-time-log__table-item-month--day-selected):hover {
    .team-time-log__table-item-month-day-number {
      background-color: rgba(#7987FF, 0.1);
    }
  }

  &:not(:first-child) {
    position: relative;
    margin-left: -1px;
  }
}

.team-time-log__table-item-month-day-number {
  background-color: #F9F9F9;
  transition: 0.2s ease-in-out;
}

.team-time-log__table-item-month--day-selected {
  .team-time-log__table-item-month-day-number {
    background-color: $color-primary;
    color: #fff;
  }
}

.team-time-log__table-item-month-day-number,
.team-time-log__table-item-month-day-duration {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #000;
  border: 1px solid #E1E1E1;
}

.team-time-log__table-item-month-day-duration {
  position: relative;
  margin-top: -1px;
}

.team-time-log__table {
  ::v-deep {
    .ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
      height: 6px;
    }

    .ps__rail-x {
      background-color: #fff;
      height: 10px;
    }
  }
}

.team-time-log__table-item__info {
  display: flex;
}

.team-time-log__table-item__tasks {
  padding: 20px 20px 20px 140px;
  background-color: #F9F9F9;

  .team-time-log__table-item__tasks-item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .team-time-log__table-item__project-alias {
    margin-bottom: 15px;
  }

  ul {
    padding-left: 30px;
    margin: 0;
    font-size: 14px;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.2s infinite ease-in-out;
}
.loader {
  color: $color-primary;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  margin: 20px auto 40px;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}

.month-selector {
  display: flex;
  justify-content: flex-end;
}

.team-time-log__table-item-month-info {
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 5px 7px;
  background: #f9f9f9;
  min-height: 35px;
  align-items: center;
  max-width: 125px;
  cursor: pointer;
  border: 1px solid #E1E1E1;
  transition: 0.2s ease-in-out;

  &:not(.team-time-log__table-item-month-info--month-selected):hover {
    background-color: rgba(#7987FF, 0.1);
  }
}

.team-time-log__table-item-month-info--month-selected {
  background-color: $color-primary;
  color: #fff;
}

.team-time-log__table-item-month-days {
  display: flex;
}

.team-time-log__table-item-month-week-head {
  border: 1px solid #E1E1E1;
  margin-bottom: 10px;
  background-color: #F9F9F9;
  display: flex;
  justify-content: flex-end;
  padding: 5px 7px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:not(.team-time-log__table-item-month--week-selected):hover {
    background-color: rgba(#7987FF, 0.1);
  }
}

.team-time-log__table-item-month-week {
  &:not(:first-child) {
    .team-time-log__table-item-month-week-head {
      border-left: 0;
    }

    .team-time-log__table-item-month-day:first-child {
      .team-time-log__table-item-month-day-number,
      .team-time-log__table-item-month-day-duration {
        border-left: 0;
      }
    }
  }
}

.team-time-log__table-item-month--week-selected {
  background-color: $color-primary;
  color: #fff;
}

.team-time-log__by-projects-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.team-time-log__by-projects-list {
  list-style-type: disc;
  line-height: 1.4;
}

.user-tz {
  font-size: 10px;
  font-weight: 400;
  color: #dc3545;
}
</style>
